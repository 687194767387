#orderAccordionDiv {
  /* Each row is 44px tall. Multiply the rows desired by 44px */
  min-height: 220px;
}
#orderAccordion {
  /* Each row is 44px tall. Multiply the rows desired by 44px */
  min-height: 220px;
}

#orderLoader {
  margin-top: 100px !important;
}

@media screen and (max-width: 576px) {
  .order-h5-responsive{
      font-size:12px;
  }
}