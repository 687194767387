.sizeSelectorButton {
  width: 80px;
}

.sizeSelectorButtonOneSize {
  width: 120px;
}

.leftBorderFix {
  border-width: 1px!important
}

.angledStrike {
  /* background: linear-gradient(to left bottom, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%); */
  /* background: linear-gradient(to left bottom, transparent 47.75%, #55595C 49.5%, #55595C 50.5%, transparent 52.25%); */
  /* background: linear-gradient(45deg, transparent 47.75%, #55595C 49.5%, #55595C 50.5%, transparent 52.25%); */
  background: linear-gradient(45deg, transparent 47.75%, #919aa1 49.5%, #919aa1 50.5%, transparent 52.25%);
}
.withpadding {
  padding: 0 0.15em;
}

.ribbon {
  background-color: #919aa1;
  height: 1.8rem;
  width: 1px; /* this keeps it from shrinking in width once we add absolute positioning to it */
  position: absolute;
  transform: rotate(-45deg);
  top: 0.5rem;
  left: 2.4rem;
  z-index: 1;
}

.oneSizeRibbon {
  background-color: #919aa1;
  height: 2.2rem;
  width: 1px; /* this keeps it from shrinking in width once we add absolute positioning to it */
  position: absolute;
  transform: rotate(-45deg);
  top: 0.3rem;
  left: 3.7rem;
  z-index: 1;
}