/* Product transition animations for use with React Transition Group */
.product-transition-enter {
  opacity: 0;
}
.product-transition-enter-active {
  opacity: 1;
  transition: opacity 1200ms;
}
.product-transition-exit {
  opacity: 1;
}
.product-transition-exit-active {
  opacity: 0;
  transition: opacity 1200ms;
}