img.colorButton {
  /* as of 3/1/2021 this is used simply as a way of selecting all color button elements */
  /* except for the css below :) */
  cursor: pointer
}

.colorButtonActiveBorder {
  border: 1px black solid;
}

.colorButtonActiveBorderPermanent {
  border: 1px black solid;
}