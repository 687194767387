.fa-spinner {
  animation: fa-spin 2s linear infinite;
}

.wishListIcon {
  color: #D9534F;
  transition: transform .3s ease;
  cursor: pointer;
}

.wishListIcon:hover {
  transform: scale(1.2);
}

.wishListIconDisabled {
  color: #cbcfd1;
}