#videoSlide {
  position: relative;
}

.productDetailsCarouselImage {
  /* height: 200px; */
  /* width: auto; */
  /* margin: 0rem 1rem; */
  width: 100%;
  height: auto;
}

.carouselPlayIcon {
  position: absolute;
  left: 42%;
  top: 39%;
  /* color:rgba(235, 235, 235, 0.774) */
  /* color:rgba(95, 95, 95, 0.774) */
  /* color:rgba(66, 66, 66, 0.774) */
  /* color:rgba(54, 54, 54, 0.774); */
  /* color:rgba(39, 39, 39, 0.774); */
  color:rgba(27, 27, 27, 0.774);
  cursor: pointer;
}

.selectedBorderCarousel {
  border: 2px solid black;
}

.productDetailsCarouselDot {
  border: 0;
  width: 100%;
  height: 100%;
}

/* From the documentation at:  https://express-labs.github.io/pure-react-carousel/ */
/* Wrap the <Slider />, <ButtonBack />, <ButonNext /> components in a div with relative positioning. Add absolute positioning to the buttons */

#backButton {
  position: absolute;
  top: 40%;
  left: -15px;
  width: 8%;
  height: 20%;
}
#nextButton {
  position: absolute;
  top: 40%;
  right: -15px;
  width: 8%;
  height: 20%;
}

.productCarouselVideoModal {
  max-width: none!important;
}

.productCarouselVideoModalContent {
  width: 100%;
  height: 80vh;

}